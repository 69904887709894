// Generic table
// -----------------------------------------------------
table {
    border-spacing: 0;
    margin: 10px 0;
    background: $light-grey-background-color;
    thead {
        tr td {
            border-bottom: 1px solid #c2c2c2;
            background-color: $light-grey-background-color !important;
        }
    }
    tr {
        .warning-cell {
            color: $error_main;
            position: absolute;
            width: 100%;
        }
        td {
            padding: 4px 8px 4px 0 !important;
            font-size: 14px !important;
            svg {
                font-size: 16px !important;
            }
            .MuiInput-root::before, .MuiInput-root::before {
                border-bottom: none !important;
            }
            .MuiFormControl-root, .MuiInput-root {
                width: 100%;
                height: 18px !important;
                input, .MuiSelect-select {
                    font-size: 12px;
                }
                svg {
                    font-size: 14px;
                }
                .MuiSelect-select {
                    padding: 0 !important;
                }
            }
        }
    }
}


// Detail section absence table
// -----------------------------------------------------
.detail-section .absence-row {
    .section-cell:nth-child(1), .section-cell:nth-child(2), .section-cell:nth-child(3) {
        max-width: 80px;
    }
}


// Detail section table
// -----------------------------------------------------
.editing {
    // outline: auto;
    // outline-color: $primary_dark;
    // outline-width: 2px;
    border: 2px solid $secondary_main;
}
.section-header {
    display: grid;
    padding: 6px 0;
    background-color: white;
    // border: 1px solid red;
    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .section-certainty {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        padding: 6px 0 0 0;
        .MuiInput-root {
            height: 14px;
            box-sizing: border-box;
            overflow: visible;
            .MuiSelect-select {
                font-size: 10px !important;
                padding: 0 18px 0 0;
                background-color: transparent;
            }
        }
    }
}
.section-subheader  {
    border-bottom: 1px solid #e2e2e2;
    margin: 2px 3px 0 3px;
    padding: 3px 5px 5px 5px;
    h4, h5 {
        font-size: 12px;
        font-weight: 400;
        color: rgb(101, 101, 101);
    }
}
.minimised .section-table {
    display: none;
}
.enquiry-detail .detail-section {
    margin-bottom: 30px;
}
.detail-section-clear {
    padding: 0 8px;
}
.detail-section { 
    margin: 0;
    position: relative;
    color: rgb(53, 53, 53) !important;
    svg {
        font-size: 18px;
    }
    .report-row:not(:last-child) {
        margin-bottom: 8px;
    }
    .report-row {
        // height: 35px;
        display: grid !important;
        grid-template-columns: 22px auto 95px 60px 75px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.089);
    }
    .status-row {
        border-bottom: 1px solid #e2e2e2;
        background: $light-grey-background-color !important;
    }
    .section-row:hover {
        background-color: rgb(241, 241, 241);
    }
    .transparent {
        background-color: transparent !important;
    }
    .section-table {
        display: flex;
        flex-direction: column;
        overflow-x: visible !important;
        box-shadow: 0 0 0 5px $light-grey-background-color inset;
        button {
            line-height: 10px;
            font-size: 12px !important;
            padding: 0;
            margin-left: auto;
        }
        .section-row {
            display: flex;
            align-items: center;
            background: $light-grey-background-color;
            padding: 0px 6px;
            gap: 6px;
            box-sizing: border-box;
            .section-cell {
                width: 100%;
                width: -moz-available;          /* WebKit-based browsers will ignore this. */
                width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
                width: fill-available;
                padding: 5px 0;
                display: grid;
                align-items: center;
                // line-height: 14px;
                // font-size: 14px;
                overflow: hidden;
                overflow-wrap: anywhere;
                // box-sizing: border-box;
                .MuiFormLabel-root {
                    line-height: 10px;
                }
                .MuiInput-input {
                    padding: 0 !important;
                }
            }
        }
    }
}

// Form automation details
// ---------------------------------------------------------------------
.automation-diagnostics {
    .diagnostics-section {
        margin: 30px 0;
        h4 {
            margin-left: 10px;
        }
    }
    .diagnostics-field:nth-child(even){
        background-color: #f9f9f9;
    }
    .diagnostics-field {
        display: grid;
        grid-template-columns: auto 200px 120px;
        margin: 4px 0;
        padding: 3px 10px;
        // font-size: 12px;
        span:is(:last-child) {
            height: fit-content;
            margin: auto 0;
            font-weight: 500;
        }
        .genuine-value {
            color: $success_main;
        }
        .guessed-value {
            color: $warning_main;
        }
        .false-value {
            color: $error_main;
        }
    }
    .column-headers {
        font-weight: 500;
        border-bottom: 1px solid #d0d0d0;
        margin-bottom: 0;
        background-color: #f2f2f2 !important;
    }
}


// Tool table
// -----------------------------------------------------
.tool-table {
    display: grid;
    padding: 5px 12px;
    border-radius: 10px;
    background-color: #3c3c3c;
    overflow-x: auto;
}


// Report Management table
// -----------------------------------------------------
.table-report-management {
    height: inherit;
    background-color: transparent;
    overflow-x: hidden;
    a {
        color: white !important;   
    }
    .ReactVirtualized__Table{
        // All cells
        .ReactVirtualized__Table__headerColumn:first-child, .ReactVirtualized__Table__rowColumn:first-child {
            padding-left: 10px;
        }
        .ReactVirtualized__Table__headerColumn:last-child, .ReactVirtualized__Table__rowColumn:last-child {
            padding-right: 10px;
        }
    }
}



// Questionnaire select table
// -----------------------------------------------------



// React virtualized table
// -----------------------------------------------------
.rv-table-wrapper {
    height: 100%; 
    width: 100%; 
    overflow-y: hidden; 
    overflow-x: scroll;
    margin-bottom: 5px;
}
.ReactVirtualized__Table{

    transition: all none !important;

    .ReactVirtualized__Table__Grid:focus{ outline: none !important }

    .ReactVirtualized__Table__headerColumn {
        display: flex;
        // flex-grow: 1 !important;
        flex-shrink: 0 !important;
        align-items: center;
        padding-right: 3px;
    }
    .ReactVirtualized__Table__headerTruncatedText, .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn{
        // flex-grow: 1 !important;
        flex-shrink: 0 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }

    .ReactVirtualized__Table__row, .ReactVirtualized__Table__headerRow {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        box-sizing: border-box;
        padding: 0 8px 0 8px;
        padding-right: unset !important;
        cursor: pointer;
        * {
            cursor: pointer;
        }
    }
    .ReactVirtualized__Table__headerRow{
        font-weight: 600;
        box-shadow: 0 0 4px 0 #00000047;
        background-color: #7d7d7d13;
        z-index: 2;
        position: relative;
    }
    .ReactVirtualized__Table__row:focus, .ReactVirtualized__Table__row:hover {
        background-color: rgba(0, 0, 0, 0.034) !important;
    }
    .checkbox-column{
        display: flex;
        align-items: center;
        input{
            width: 15px;
            height: 15px;
            margin: auto;
            position: relative;
            z-index: 1000;
        }
    }
    .ReactVirtualized__Table__row:nth-child(even){
        // background-color: #f9f9f9;
    }
}

.rv-table-md {
    .ReactVirtualized__Table__row, .ReactVirtualized__Table__headerRow button {
        font-size: 14px !important;
    }
}
.rv-table-sm {
    .ReactVirtualized__Table__row, .ReactVirtualized__Table__headerRow button {
        font-size: 12px !important;
    }
}