// Navbar/Header
// -----------------------------------------------------
.nav-header {
    height: 54px;
    max-height: 54px !important;
    min-height: 54px !important;
    // background: linear-gradient(100deg, $primary_dark -10%, #48628e 54%, $primary_dark 110%) !important;
    background: linear-gradient(-45deg,#79535D,#314A94);
    display: flex;
    gap: 15px;
    // background-color: grey !important;
    .MuiToolbar-root {
        min-height: 54px !important;
    }
    .header-status {
        color: rgb(161, 161, 161);
        display: flex;
        align-items: center;
        text-align: right;
        margin-left: auto;
        small {
            font-size: 8px !important;
        }
        cursor: pointer;
        button {
            padding: 0;
            svg {
                font-size: 20px;
            }
        }
    }
    .grow {
        flex-grow: 1;
    }
    .logo-button {    
        height: 25px;
        margin: 0;
        padding: 4px 15px;
        img, svg{
            width: auto;
            height: 100%;
        }
    }
    .main-menu-toggler {
        padding: 0;
        svg {
            font-size: 30px;
        }
    }
    .main-menu{
        min-width: 250px;
    }
    .desktop-section {
        display: flex;
    }
    .mobile-section {
        display: none,
    }
}


// Menu - inline
.inline-menu {
    ul {
        min-width: 150px !important;
    }
    .MuiMenuItem-root {
        width: 100%;
        max-height: 35px !important;
        display: flex;
        padding: 4px 15px 4px 15px;
        font-size: 12px !important;
        // justify-content: space-between;
        // align-items: center;
        svg {
            font-size: 12px;
            color: $info_main;
        }
    }
    .inline-menu-title {
        font-size: 10px !important;
        // background-color: whitesmoke;
        // box-shadow: 0 0 10px 0 #00000010;
        // border-bottom: 0.5px solid $secondary_light;
        padding: 3px 15px;
        color: $info_main;
        pointer-events: none;
    }
    .Mui-selected {
        background-color: white;
        font-weight: 600 !important;
    }
}
.menu-divider {
    width: 100%;
    height: 30px;
    padding: 2px 6px;
    border-radius: 5px;
    // margin: 3px 0 !important;
    box-sizing: border-box;
    display: flex !important; 
    justify-content: space-between;
    align-items: center !important; 
    background-color: $light-grey-background-color !important;
    * {
        color: rgb(31, 31, 31) !important;
    }
    button {
        width: 100%;
        display: flex !important; 
        justify-content: space-between;
        text-transform: none;
        font-size: 12px !important;
        font-weight: 600 !important;
    }
}



// Menu - main
// -----------------------------------------------------
.main-menu{
    // width: 270px;
    width: auto;
    height: 100%;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: 95px auto 63px;
    background-color: #1f1e23;
    * {
        font-weight: 400;
        color: rgb(224, 224, 224);
    }
    .main-menu-header{
        padding: 22px 20px;
        display: grid;
    }
    .main-menu-body{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 20px;
        * {
            font-weight: 300;
            font-size: 16px;
        }
        .main-menu-nav{
            display: flex;
            flex-direction: column;
            .main-menu-link {
                height: 55px;
                width: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center !important;
                text-transform: none;
                padding: 0;
                font-size: 18px;
                * {
                    cursor: pointer;
                }
                svg {
                    padding: 10px 10px 10px 0;
                }
            }
        }
    }
    .main-menu-footer {
        padding: 0px 20px;
    }
}


// Breadcrumbs for navigating folders
// -----------------------------------------------------
.breadcrumbs{
    max-width: 90vw;
    display: flex;
    align-items: center;
    gap: 7px;
    .breadcrumb-delimiter {
        // margin: 0 5px;
        cursor:default
    }
    .breadcrumb-link {
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 1px solid rgba(128, 128, 128, 0);
    }
    .breadcrumb-link:is(:first-child) {
        display: contents;
    }
    .breadcrumb-link:not(:last-child) {

        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        // font-weight: 400 !important;
        // color: #8b8b8b;
    }
    .breadcrumb-link:is(:last-child) {
        width: auto;
    }
    .breadcrumb-link:hover{
        color: $theme-color;
    }
    .breadcrumb-link:focus{
        outline: none;
        background-color: whitesmoke;
        border-color: $primary_main !important;
    }
    svg{
        font-size: 15px;
        // color: #8b8b8b;
    }
}

// Tabs
.MuiTabs-root {
    min-height: unset !important;
    height: 30px;
    button {
        min-height: unset !important;
        font-size: 12px;
        padding-top: 7px;
        padding-bottom: 10px;
    }
}



// Mobile navbar
// -----------------------------------------------------
@media only screen and (max-width: 600px) {
    .nav-header {
        .desktop-section {
            display: none;
        }
        .mobile-section {
            display: flex,
        }
    }
}